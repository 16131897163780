<template>
  <div class="row">
    <div class="col-lg-12">
      <w-input tag="b-form-checkbox" v-model="$v.orderIsGiftVoucher" class="my-4">
        <div v-if="$route.params.type == 'gamecamp'">
          Mám záujem o uplatnenie rekreačného poukazu
        </div>
        <div v-else-if="$route.params.type == 'summer-game-dev-academy'">
          Mám predbežný záujem o účasť na letnej škole SGD 2022
        </div>
        <div v-else>
          Kurz ako darček - mám záujem o 
          <a target="_blank" href="./img/Vianocny-kupon.png">darčekovú poukážku</a> 
          <span v-if="$route.params.type != 'levelup-your-skills'"> s menom študenta</span>(emailom, formát pdf)
        </div>
      </w-input>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    validations() {
      return {
        orderIsGiftVoucher: {}
      }
    },
    computed: {
      ...mapFields('order', { orderIsGiftVoucher: 'isGiftVoucher' })
    }
}
</script>

<style lang="sass" scoped>
  .label
    font-size: 14px
</style>